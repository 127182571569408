.background {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.img {
  background-color: #f5f5f5;
  object-fit: contain;
  filter: blur(0.5rem);
  height: 100%;
  width: 100%;
}

.modal {
  border: none;
  outline: none;

  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.81);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.modal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.button_group {
  display: flex;
  gap: 1rem;

  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.button {
  border: none;
  outline: none;

  padding: 0.5rem 1rem;
  border-radius: 0.2rem;

  cursor: pointer;

  background: rgb(27, 153, 71);

  transition: all 0.2s ease-in-out;

  flex: 1;
}

.button:active {
  transform: translateY(2px);
}

.button:hover {
  filter: brightness(0.9);
}

.button:focus {
  box-shadow: 0 0 0 0.15rem rgba(27, 153, 71, 0.293);
}
